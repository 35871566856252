import { type IntlShape } from 'react-intl';

import { logToSentry } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, type GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import { type DurationOption } from '../../../shared/enums/durationOption';
import { type Filter, type RangeFilter } from '../../../shared/types/search';
import { formatPriceRange } from '../../helper/formatPrice';
import { getActiveFilters } from '../../hooks/getActiveFilters';
import { durationOptionMap } from './filterMessages';

const trackCloseFilterPanel = (
    filter: Filter,
    intl: IntlShape,
    listType: GA4FilterListType,
    trackingData: {
        locale: string;
        currencyCode: string;
        itemCount: number;
    },
): void => {
    const { location, productAttributes, price } = filter;
    const { locale, currencyCode, itemCount } = trackingData;
    const activeTrackingValues = [
        location?.name && location.name,
        location?.distance && `${location.distance}km`,
        formatPriceRange({
            locale,
            currencyCode,
            min: price?.min || 10,
            max: price?.max || 500,
        }),
    ]
        .concat(
            productAttributes &&
                Object.entries(productAttributes).flatMap(([productAttribute, selectedItems]) =>
                    selectedItems.map((item: string | RangeFilter) => {
                        if (typeof item === 'string') return item;

                        const option =
                            durationOptionMap[[item.min, item.max].join('_') as DurationOption];
                        try {
                            return intl.formatMessage(option);
                        } catch {
                            logToSentry(
                                `i18n key not found for product attribute: ${productAttribute} and values: ${
                                    item.min as unknown as string
                                }, ${item.max as unknown as string}`,
                            );
                            return '';
                        }
                    }),
                ),
        )
        .filter(Boolean)
        .join('_');

    const activeFilters = getActiveFilters(filter);

    trackFilterInteraction(
        'SetFilter',
        activeTrackingValues,
        {
            eventName: GA4EventName.ClickButton,
            click_element: 'Set all filters',
            click_text: `${itemCount} ${itemCount > 1 ? 'Ergebnisse' : 'Ergebnis'} anzeigen`,
            click_value: `${itemCount}`,
            list_type: listType,
        },
        activeFilters,
    );
};

export { trackCloseFilterPanel };
