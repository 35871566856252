import { type MouseEvent } from 'react';
import { type IntlShape } from 'react-intl';

import { GA4ItemListName, GA4ItemListType } from '@jsmdg/tracking';
import { MapProductTilePopUp } from '@jsmdg/yoshi';
import { type Location, type Product } from '../../../shared/types/searchResponse';
import { useOnProductTileClickAndImpression } from '../../hooks/useOnProductTileClickAndImpression';
import { useWishListToggle } from '../../hooks/useWishListToggle';
import { type CustomerState } from '../../types/wishlist';

type MapViewPopUpProductProps = {
    readonly product: Product;
    readonly price?: string;
    readonly originalPrice?: string;
    readonly closePopup: () => void;
    readonly popUpMessages: Record<string, string>;
    readonly customerState: CustomerState;
    readonly errorLogger: (err: Error, message?: string | undefined) => void;
    readonly initialWishlist?: string[];
    readonly location?: Location;
    readonly indexName?: string;
    readonly queryId?: string;
    readonly position: number;
    readonly intl: IntlShape;
};

export const MapViewPopUpProduct = ({
    closePopup,
    customerState,
    errorLogger,
    indexName,
    initialWishlist,
    intl,
    location,
    originalPrice,
    popUpMessages,
    position,
    price = '',
    product,
    queryId,
}: MapViewPopUpProductProps): JSX.Element => {
    const { images, participants, rating, title, url } = product;

    const { isOnWishlist, onWishlistButtonClick } = useWishListToggle(
        product,
        popUpMessages,
        {
            customerState,
            errorLogger,
            initialWishlist,
        },
        GA4ItemListName.Category,
        GA4ItemListType.MapViewOverlay,
    );
    const a11yWishlistButton = isOnWishlist
        ? popUpMessages.screenReaderTextForWishlistRemove
        : popUpMessages.screenReaderTextForWishlistAdd;

    const { onProductTileClick, setImpressionTrackedElement } = useOnProductTileClickAndImpression(
        product,
        position,
        intl,
        {
            isListView: false,
            indexName,
            queryId,
            trackingListName: undefined,
            hasLocationFilter: true,
            isModalView: true,
            isOverlay: true,
        },
    );

    const onProductClick = async (event?: MouseEvent<HTMLAnchorElement>): Promise<void> => {
        event?.preventDefault();
        await onProductTileClick();
    };

    let participantsLabel;

    if (participants?.max)
        participantsLabel =
            participants?.max > 1
                ? `${participants?.max as unknown as string} Personen`
                : `${participants?.max as unknown as string} Person`;

    return (
        <div data-testid="map-view-pop-up">
            <MapProductTilePopUp
                name={title}
                price={price}
                originalPrice={originalPrice}
                image={{
                    url: images[0].url,
                    alt: images[0].alt,
                    lazyLoad: false,
                }}
                url={url}
                onProductClick={async event => onProductClick(event)}
                locationLabel={location?.name}
                rating={rating}
                participantsLabel={participantsLabel}
                isOnWishlist={isOnWishlist}
                a11yPdpRedirectLabel={popUpMessages.a11yPdpRedirectLabel}
                a11yWishlistButton={a11yWishlistButton}
                a11yCloseText={popUpMessages.closePopup}
                toggleWishlist={onWishlistButtonClick}
                onCloseClick={closePopup}
                ref={setImpressionTrackedElement}
            />
        </div>
    );
};
