import classNames from 'classnames';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { type FacetFilter } from '../../../../../shared/types/facetFilter';
import { type Filter, type Sorting } from '../../../../../shared/types/search';
import { type SearchReducerActionType } from '../../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../../types/searchReducer';
import { FilterSidePanelBody } from './FilterSidePanelBody';
import { FilterSidePanelFooter } from './FilterSidePanelFooter';
import styles from '../Filter.module.scss';

type FilterSidePanelProps = {
    readonly showLocationFilter: boolean;
    readonly filter: Filter;
    readonly locale: string;
    readonly shouldReset: boolean;
    readonly isInitialLocation: boolean;
    readonly isInitialPrice: boolean;
    readonly nbItems: number;
    readonly listType: GA4FilterListType;
    readonly hasFilterChanged: boolean;
    readonly sorting?: Sorting;
    readonly geoLocationError?: GeolocationPositionError;
    readonly isMapView?: boolean;
    readonly facets?: FacetFilter[];
    readonly paginationLimit?: number;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly resetFilter: () => void;
    readonly closeFilterPanel: () => void;
};

const FilterSidePanelContent = ({
    closeFilterPanel,
    facets,
    filter,
    geoLocationError,
    hasFilterChanged,
    isInitialLocation,
    isInitialPrice,
    isMapView,
    listType,
    locale,
    nbItems,
    onFilterChange,
    paginationLimit,
    resetFilter,
    shouldReset,
    showLocationFilter,
    sorting,
}: FilterSidePanelProps): JSX.Element => {
    const onSortingChangeHandler = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => {
        onFilterChange(type, value, name);
        closeFilterPanel();
    };

    return (
        <>
            {isMapView && (
                <FilterSidePanelFooter
                    filter={filter}
                    locale={locale}
                    itemCount={nbItems}
                    hasFilterChanged={hasFilterChanged}
                    resetFilter={resetFilter}
                    closeFilterPanel={closeFilterPanel}
                    className={classNames(styles.filterPanelFooter, 'position-relative h-auto')}
                    isMapView
                    listType={listType}
                />
            )}

            <FilterSidePanelBody
                sorting={sorting}
                filter={filter}
                showLocationFilter={showLocationFilter}
                shouldReset={shouldReset}
                isInitialLocation={isInitialLocation}
                isInitialPrice={isInitialPrice}
                onFilterChange={onFilterChange}
                onSortingChange={onSortingChangeHandler}
                geoLocationError={geoLocationError}
                facets={facets}
                paginationLimit={paginationLimit}
                isMapView={isMapView}
                className={
                    isMapView ? classNames(styles.filterPanelBody, 'h-100 overflow-scroll') : ''
                }
            />
            {!isMapView && (
                <FilterSidePanelFooter
                    filter={filter}
                    locale={locale}
                    itemCount={nbItems}
                    hasFilterChanged={hasFilterChanged}
                    resetFilter={resetFilter}
                    closeFilterPanel={closeFilterPanel}
                    listType={listType}
                />
            )}
        </>
    );
};

export { FilterSidePanelContent };
