import { DeviceOs } from '../enums/deviceOsType';

export const detectMobileOS = (): DeviceOs => {
    const userAgent = navigator.userAgent;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return DeviceOs.IOS;
    }

    if (/android/i.test(userAgent)) {
        return DeviceOs.Android;
    }

    return DeviceOs.Unknown;
};
