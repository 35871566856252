import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { type GA4FilterListType } from '@jsmdg/tracking';
import { Button, ChevronIcon, PureButton, RenderType } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../../../../shared/types/fragmentContext';
import { type Filter } from '../../../../../shared/types/search';
import { trackCloseFilterPanel } from '../../filter.tracking';
import styles from '../../Filter.module.scss';

type FilterSidePanelFooterProps = {
    readonly filter: Filter;
    readonly locale: string;
    readonly itemCount: number;
    readonly hasFilterChanged: boolean;
    readonly isMapView?: boolean;
    readonly className?: string;
    readonly resetFilter: () => void;
    readonly closeFilterPanel: () => void;
    readonly listType: GA4FilterListType;
};

const FilterSidePanelFooter = ({
    className,
    closeFilterPanel,
    filter,
    hasFilterChanged,
    isMapView,
    itemCount,
    listType,
    locale,
    resetFilter,
}: FilterSidePanelFooterProps): JSX.Element => {
    const intl = useIntl();
    const { tenantConfig } = useFragmentContext<FragmentContext>();
    const { currency } = tenantConfig;

    const onCloseFilterPanel = (): void => {
        closeFilterPanel();
        trackCloseFilterPanel(filter, intl, listType, {
            locale,
            currencyCode: currency.code,
            itemCount,
        });
    };

    return (
        <div
            className={classNames(
                styles.submitContainer,
                'd-flex flex-column w-100 justify-content-between justify-content-xs-normal',
                {
                    'position-fixed  bottom-0 p-2x pr-xs-4x pl-xs-4x': !isMapView,
                    'p-3x': isMapView,
                },
                className,
            )}
        >
            {isMapView && (
                <PureButton
                    onClick={closeFilterPanel}
                    className={classNames(styles.backButton, 'd-flex align-items-center mb-3x')}
                >
                    <ChevronIcon className={classNames(styles.icon, 'mr-1x')} />
                    <FormattedMessage defaultMessage="Filter" />
                </PureButton>
            )}

            <div className="d-flex flex-row justify-content-between">
                <Button
                    as={RenderType.Link}
                    onClick={resetFilter}
                    disabled={!hasFilterChanged}
                    className={classNames(
                        styles.resetButton,
                        'text-decoration-underline',
                        !hasFilterChanged && 'disabled',
                        {
                            'pl-0 pr-4x': isMapView,
                        },
                    )}
                >
                    <FormattedMessage defaultMessage="Alle zurücksetzen" />
                </Button>
                <Button
                    onClick={onCloseFilterPanel}
                    className={classNames(styles.submitButton, {
                        [styles.modalSubmitButton]: isMapView,
                    })}
                >
                    <span className={styles.itemCount}>
                        <FormattedMessage
                            defaultMessage="{itemCount} {itemCount, plural, one {Ergebnis} other {Ergebnisse}} anzeigen"
                            values={{
                                itemCount,
                            }}
                        />
                    </span>
                </Button>
            </div>
        </div>
    );
};

export { FilterSidePanelFooter };
