import { createRoot } from 'react-dom/client';
import { type IntlShape } from 'react-intl';
import {
    type DivIcon,
    divIcon,
    type Icon,
    icon,
    latLng,
    type LatLngBounds,
    latLngBounds,
    type Layer,
    type Map,
    Marker,
    marker as mapMarker,
    type MarkerClusterGroup,
    markerClusterGroup,
    Point,
    popup as mapPopup,
} from 'leaflet';

import { MapMarkerIconSrc } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type Product } from '../../shared/types/searchResponse';
import { MapViewPopUpProduct } from '../components/MapView/MapViewPopUpProduct';
import { trackClickOnPin } from '../components/MapView/mapViewTracking';
import { type CustomMapMarker } from '../types/customMapMarker';
import { type ProductListItem } from '../types/productListItem';
import { type UrlParameter } from '../types/urlParameterType';
import { type WishlistData } from '../types/wishlist';
import { blacklistSearchFilteringParams } from './mapUrlParameters';
import { renderPrice } from './renderPrice';

export const getMapContainer = (): HTMLElement | null => {
    const container = document.querySelector(`#map`) as HTMLElement;
    if (!container) return null;
    return container;
};

export const cleanupMapRef = (ref: React.MutableRefObject<Map | null>) => {
    const container = getMapContainer();
    if (container) {
        container.removeAttribute('data-map-initialized');
    }

    ref.current?.off();
    ref.current?.remove();
    ref.current = null;
};

export const getUrlParameters = (): UrlParameter => {
    return {
        ...Object.fromEntries(
            blacklistSearchFilteringParams(
                new URLSearchParams(typeof window === 'undefined' ? '' : window.location.search),
                false,
            ),
        ),
    };
};

export const createClusterIcon = (childCount: number, isHighlighted: boolean): DivIcon => {
    let iconSize = isHighlighted ? new Point(52, 52) : new Point(50, 50);
    let clusterSizeName = 'Large';
    if (childCount < 10) {
        iconSize = isHighlighted ? new Point(42, 42) : new Point(40, 40);
        clusterSizeName = 'Small';
    } else if (childCount < 100) {
        iconSize = isHighlighted ? new Point(47, 47) : new Point(45, 45);
        clusterSizeName = 'Medium';
    }

    const clusterClassName = isHighlighted
        ? `customClusterHovered customClusterHovered${clusterSizeName}`
        : `customCluster customCluster${clusterSizeName}`;

    return divIcon({
        html: `<div><span>${childCount}</span></div>`,
        className: clusterClassName,
        iconSize,
    });
};

export const createIcon = (eventName?: string): Icon => {
    let className: string;
    switch (eventName) {
        case 'hover':
            className = 'mapMarkerHover';
            break;
        case 'click':
            className = 'mapMarkerClick';
            break;
        default:
            className = 'mapMarker';
            break;
    }

    return icon({
        iconUrl: MapMarkerIconSrc,
        className,
    });
};

export const calculateMapView = (lat: number, lng: number, distanceInKm: number): LatLngBounds => {
    const distanceInMeters = distanceInKm * 1_000;
    const latRad = (lat * Math.PI) / 180;
    const earthRadius = 6_378_137;
    const angularDistance = distanceInMeters / earthRadius;

    const northEdgeLat = Number(lat) + Number(angularDistance * (180 / Math.PI));

    const eastEdgeLng =
        Number(lng) + Number((angularDistance * (180 / Math.PI)) / Math.cos(latRad));

    return latLngBounds([lat, lng], [northEdgeLat, eastEdgeLng]);
};

export const getReverseGeocode = async (
    lat: number,
    long: number,
    reverseGeocode: FragmentContext['reverseGeocode'],
): Promise<string | null> => {
    let locationName;
    try {
        const geocodeResponse = await reverseGeocode(lat, long, false);

        locationName = geocodeResponse.results[0].formatted_address;
        return locationName;
    } catch {
        locationName = null;
        return locationName;
    }
};

export const getDistanceToEdgeFromCenter = (map: Map): number => {
    const bounds = map.getBounds();
    const center = map.getCenter();

    const centerLatLng = latLng(center.lat, center.lng);

    const northEdge = latLng(bounds.getNorth(), center.lng);
    const eastEdge = latLng(center.lat, bounds.getEast());
    const distanceToNorth = centerLatLng.distanceTo(northEdge);
    const distanceToEast = centerLatLng.distanceTo(eastEdge);

    if (distanceToNorth > distanceToEast) {
        return Math.round(distanceToEast / 1_000);
    }

    return Math.round(distanceToNorth / 1_000);
};

export const createMarkerCluster = (): MarkerClusterGroup => {
    const clusters = markerClusterGroup({
        animate: true,
        showCoverageOnHover: false,
        removeOutsideVisibleBounds: true,
        iconCreateFunction(cluster) {
            const childCount = cluster.getChildCount();
            const hasRelatedProduct = cluster
                .getAllChildMarkers()
                .some((marker: CustomMapMarker) => {
                    return marker.isHovered;
                });

            return createClusterIcon(childCount, hasRelatedProduct);
        },
    });

    clusters.on('spiderfied', event => {
        event.cluster.setOpacity(0);
    });

    return clusters;
};

export const addMarkersToCluster = (
    mapData: {
        map: Map;
        markers: MarkerClusterGroup;
        productListItems: ProductListItem[];
        queryId?: string;
        indexName?: string;
    },
    popUpMessages: Record<string, string>,
    intl: IntlShape,
    wishlistData: WishlistData,
    modalState: { isSidePanelOpen: boolean; isDesktop: boolean },
): void => {
    const { indexName, map, markers, productListItems, queryId } = mapData;
    const { customerState, errorLogger, initialWishlist } = wishlistData;
    const { isDesktop, isSidePanelOpen } = modalState;

    if (!map || !getMapContainer()) return;

    const closePopup = (): void => {
        map.closePopup();
    };

    markers.clearLayers();
    productListItems.forEach(item => {
        const currentItem = { ...item };

        if (currentItem.product?.locations) {
            const popupNode = document.createElement('div');
            const root = createRoot(popupNode);

            const popup = mapPopup({
                closeButton: false,
                autoClose: false,
                closeOnClick: true,
                closeOnEscapeKey: true,
                minWidth: 350,
                autoPanPaddingTopLeft: new Point(isSidePanelOpen && isDesktop ? 425 : 0, 300),
            })
                .setLatLng(map.getBounds().getCenter())
                .setContent(popupNode);

            currentItem.product.locations.forEach(location => {
                const marker = mapMarker([location.lat, location.long], {
                    icon: createIcon(),
                }) as CustomMapMarker;
                marker.productID = currentItem.product?.productId;
                marker.bindPopup(popup);
                let isClicked = false;
                marker.on('click', () => {
                    const popupBox = (product: Product): JSX.Element => {
                        return (
                            <MapViewPopUpProduct
                                product={product}
                                price={renderPrice(product.price, intl)}
                                originalPrice={renderPrice(product.originalPrice, intl)}
                                closePopup={closePopup}
                                customerState={customerState}
                                initialWishlist={initialWishlist}
                                errorLogger={errorLogger}
                                popUpMessages={popUpMessages}
                                location={location}
                                indexName={indexName}
                                queryId={queryId}
                                position={1}
                                intl={intl}
                            />
                        );
                    };

                    trackClickOnPin(currentItem.product?.title ?? '');
                    if (currentItem.product) {
                        markers.getLayers().forEach((layer: Layer) => {
                            if (layer instanceof Marker) {
                                layer.setIcon(createIcon());
                            }
                        });
                        root.render(popupBox(currentItem.product));
                        marker.openPopup();
                        marker.setIcon(createIcon('click'));
                        isClicked = true;
                    }
                });

                marker.on('mouseover', () => {
                    if (!isClicked && currentItem.product) {
                        marker.setIcon(createIcon('hover'));
                    }
                });

                marker.on('mouseout', () => {
                    if (!isClicked && currentItem.product) {
                        marker.setIcon(createIcon());
                    }
                });

                markers.addLayer(marker);
            });
        }
    });

    map.addLayer(markers);
};
