import { FormattedMessage } from 'react-intl';

import styles from '../Filter.module.scss';

const FilterSidePanelHeader = (): JSX.Element => (
    <div className={styles.headline} data-testid="filter-side-panel-header">
        <FormattedMessage defaultMessage="Filter" />
    </div>
);

export { FilterSidePanelHeader };
