import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { type GA4FilterListType } from '@jsmdg/tracking';
import { SidePanel, SlidePanelDirection } from '@jsmdg/yoshi';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter, type Sorting as SortingType } from '../../../../shared/types/search';
import { type SearchReducerActionType } from '../../../reducers/searchReducer';
import { type SearchReducerValue } from '../../../types/searchReducer';
import { FilterSidePanelHeader } from '.';
import { FilterSidePanelContent } from './FilterSidePanelContent/FilterSidePanelContent';
import styles from './Filter.module.scss';

type FilterSidePanelProps = {
    readonly showLocationFilter: boolean;
    readonly filter: Filter;
    readonly locale: string;
    readonly shouldReset: boolean;
    readonly isInitialLocation: boolean;
    readonly isInitialPrice: boolean;
    readonly nbItems: number;
    readonly listType: GA4FilterListType;
    readonly isSidePanelOpen: boolean;
    readonly hasFilterChanged: boolean;
    readonly sorting?: SortingType;
    readonly geoLocationError?: GeolocationPositionError;
    readonly isMapView?: boolean;
    readonly facets?: FacetFilter[];
    readonly paginationLimit?: number;
    readonly className?: string;
    readonly isFilterPanelOpen?: boolean;
    readonly onFilterChange: (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        name?: string,
    ) => void;
    readonly resetFilter: () => void;
    readonly closeFilterPanel: () => void;
};

const messages = defineMessages({
    a11yCloseText: {
        defaultMessage: 'Schließen',
    },
});

const FilterSidePanel = ({
    className,
    closeFilterPanel,
    facets,
    filter,
    geoLocationError,
    hasFilterChanged,
    isFilterPanelOpen,
    isInitialLocation,
    isInitialPrice,
    isMapView,
    isSidePanelOpen,
    listType,
    locale,
    nbItems,
    onFilterChange,
    paginationLimit,
    resetFilter,
    shouldReset,
    showLocationFilter,
    sorting,
}: FilterSidePanelProps): JSX.Element => {
    const intl = useIntl();
    if (isMapView) {
        return (
            <div
                className={classNames(
                    styles.filterPanel,
                    'position-absolute start-0 top-0 bottom-0 overflow-hidden  pb-2-5x',
                    isSidePanelOpen && isFilterPanelOpen ? styles.panelOpen : styles.panelClosed,
                    className,
                )}
                data-testid="filter-side-panel"
            >
                <FilterSidePanelContent
                    filter={filter}
                    locale={locale}
                    nbItems={nbItems}
                    hasFilterChanged={hasFilterChanged}
                    sorting={sorting}
                    showLocationFilter={showLocationFilter}
                    shouldReset={shouldReset}
                    isInitialLocation={isInitialLocation}
                    isInitialPrice={isInitialPrice}
                    geoLocationError={geoLocationError}
                    facets={facets}
                    paginationLimit={paginationLimit}
                    isMapView
                    listType={listType}
                    onFilterChange={onFilterChange}
                    resetFilter={resetFilter}
                    closeFilterPanel={closeFilterPanel}
                />
            </div>
        );
    }

    return (
        <SidePanel
            direction={SlidePanelDirection.Right}
            fullWidth
            open={isSidePanelOpen}
            headerElements={<FilterSidePanelHeader />}
            headerClassname="py-1-5x px-2x"
            boxClassname="p-0"
            a11yCloseText={intl.formatMessage(messages.a11yCloseText)}
            onRequestClose={closeFilterPanel}
        >
            <FilterSidePanelContent
                filter={filter}
                locale={locale}
                nbItems={nbItems}
                hasFilterChanged={hasFilterChanged}
                sorting={sorting}
                showLocationFilter={showLocationFilter}
                shouldReset={shouldReset}
                isInitialLocation={isInitialLocation}
                isInitialPrice={isInitialPrice}
                geoLocationError={geoLocationError}
                facets={facets}
                paginationLimit={paginationLimit}
                isMapView={isMapView}
                listType={listType}
                resetFilter={resetFilter}
                onFilterChange={onFilterChange}
                closeFilterPanel={closeFilterPanel}
            />
        </SidePanel>
    );
};

// eslint-disable-next-line import/no-default-export
export default FilterSidePanel;
