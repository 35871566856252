import { useContext } from 'react';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { SearchStatus } from '../../../shared/enums/searchStatus';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type Search } from '../../../shared/types/search';
import { ProductListItemType } from '../../enums/productListItemType';
import { HoverContext } from '../../helper/mapViewProductHoverContext';
import { type SearchReducerAction } from '../../reducers/searchReducer';
import { type InitialPageFilterType } from '../../types';
import { type ProductListItem } from '../../types/productListItem';
import { ProductListPagination } from '../ProductListPagination/ProductListPagination';
import { MapViewProductTileWrapper } from './MapViewProductTileWrapper';

type MapViewProductListItemsProps = {
    readonly productListItems: ProductListItem[];
    readonly currentPageSize: number;
    readonly lazyLoad: boolean;
    readonly searchState: Search;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly nbPages: number;
    readonly offset: number;
    readonly status: SearchStatus;
    readonly queryId?: string;
    readonly indexName: string;
    readonly dispatchSearch: React.Dispatch<SearchReducerAction>;
};

const MapViewProductListItems = ({
    currentPageSize,
    dispatchSearch,
    indexName,
    initialPageFilter,
    lazyLoad,
    nbPages,
    offset,
    productListItems,
    queryId,
    searchState,
    status,
}: MapViewProductListItemsProps): JSX.Element => {
    const { path } = useFragmentContext<FragmentContext>();
    const { filter = {}, pagination, searchTerm, sorting } = searchState;

    let productPosition = 0;

    const showPagination = !!pagination && nbPages > 1;

    const { setHoveredProductId } = useContext(HoverContext);

    return (
        <div
            className={classNames('pt-2-5x pt-sm-0', {
                'pe-none opacity-25': status === SearchStatus.Pending,
            })}
            data-testid="map-view-product-list-items"
        >
            <div>
                {productListItems.map((listItem, index) => {
                    if (index >= currentPageSize) return null;

                    if (listItem.type === ProductListItemType.Product && !!listItem.product) {
                        productPosition += 1;

                        return (
                            <div
                                onMouseEnter={() => {
                                    setHoveredProductId(listItem.product?.productId as string);
                                }}
                                onMouseLeave={() => {
                                    setHoveredProductId('');
                                }}
                                key={listItem.key}
                            >
                                <MapViewProductTileWrapper
                                    product={listItem.product}
                                    position={
                                        pagination && pagination.currentPage > 1
                                            ? pagination.size * (pagination.currentPage - 1) +
                                              productPosition
                                            : productPosition
                                    }
                                    lazyLoad={lazyLoad && index !== 0}
                                    queryId={queryId}
                                    indexName={indexName}
                                    isListView
                                    hasLocationFilter={!!filter?.location?.name}
                                />
                            </div>
                        );
                    }

                    return null;
                })}
                {showPagination && (
                    <ProductListPagination
                        path={path}
                        totalPages={nbPages}
                        initialPageFilter={initialPageFilter}
                        offset={offset}
                        search={{ searchTerm, filter, sorting, pagination }}
                        isMapView
                        dispatchSearch={dispatchSearch}
                    />
                )}
            </div>
        </div>
    );
};

export { MapViewProductListItems };
