import classNames from 'classnames';

import { Badge } from '@jsmdg/yoshi';
import { type FacetFilter } from '../../../../shared/types/facetFilter';
import { type Filter } from '../../../../shared/types/search';
import styles from '../Filter.module.scss';

type AttributeAccordionTitleProps = {
    readonly facet: FacetFilter;
    readonly filter: Filter;
};

const AttributeAccordionTitle = ({ facet, filter }: AttributeAccordionTitleProps): JSX.Element => {
    const facetAttributes = filter.productAttributes?.[facet.attribute];
    return (
        <div
            className={classNames('d-flex fw-semibold', {
                [styles.activeFilter]: !!facetAttributes?.length,
            })}
        >
            {facet.name}
            {!!facetAttributes?.length && <Badge className="ml-1x">{facetAttributes.length}</Badge>}
        </div>
    );
};

export { AttributeAccordionTitle };
