import { useContext, useEffect, useMemo, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FilterListType, trackFilterInteraction } from '@jsmdg/tracking';
import {
    ArrowIcon,
    Badge,
    Breakpoint,
    Button,
    ButtonColor,
    ButtonShape,
    ChevronIcon,
    FilterIcon,
    ListViewIcon,
    Modal,
    PureButton,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { AttributeName } from '../../../shared/enums/attributeName';
import { ObjectType } from '../../../shared/enums/objectType';
import { pageConfig, type PageType } from '../../../shared/enums/pageType';
import { getFacetFilters } from '../../../shared/helpers/algoliaHelpers';
import { getAlgoliaIndex } from '../../../shared/helpers/getAlgoliaIndex';
import { type Attribute } from '../../../shared/types/attribute';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import {
    type Filter,
    type LocationFilter as LocationFilterType,
    type Search,
    type Sorting,
} from '../../../shared/types/search';
import { AlgoliaIndexId } from '../../enums/algoliaIndexId';
import { DeviceOs } from '../../enums/deviceOsType';
import { detectMobileOS } from '../../helper/detectMobileOs';
import { getAlgoliaScopedResults } from '../../helper/getAlgoliaScopedResults';
import { cleanupMapRef, getUrlParameters } from '../../helper/mapViewHelper';
import { HoverContext } from '../../helper/mapViewProductHoverContext';
import { useProductListItems } from '../../hooks';
import { useAlgoliaContext } from '../../hooks/useAlgoliaContext';
import { useAlgoliaProducts } from '../../hooks/useAlgoliaProducts';
import { useTrackProductListItems } from '../../hooks/useTrackProductListItems';
import { useUrlQueryUpdate } from '../../hooks/useUrlQueryUpdate';
import {
    type SearchReducerAction,
    SearchReducerActionType,
    type SearchReducerState,
} from '../../reducers/searchReducer';
import { type InitialPageFilterType } from '../../types';
import { type SearchReducerValue } from '../../types/searchReducer';
import { type UrlParameter } from '../../types/urlParameterType';
import FilterSidePanel from '../Filter/FilterSidePanel/FilterSidePanel';
import { LocationFilter } from '../Filter/Location';
import { PriceFilter } from '../Filter/Price';
import { ProductFacets } from '../Filter/ProductFacets';
import { useActiveFiltersCount } from '../Filter/useActiveFiltersCount';
import { hasInitialLocation, hasInitialPrice } from '../Filter/utils';
import { MapView } from './MapView';
import { MapViewProductListItems } from './MapViewProductListItems';
import { trackCloseMapViewClick, trackToggleList } from './mapViewTracking';
import styles from './MapViewModal.module.scss';

type MapViewModalProps = {
    readonly initialSearch?: Search;
    readonly showLocationFilter: boolean;
    readonly pageType: PageType;
    readonly initialPageFilter?: InitialPageFilterType;
    readonly initialFilter?: Filter;
    readonly lazyLoad: boolean;
    readonly pageId?: string;
    readonly pageTitle?: string;
    readonly indexName: string;
    readonly searchState: SearchReducerState;
    readonly geoLocationError?: GeolocationPositionError;
    readonly isMapViewModalOpen: boolean;
    readonly dispatchSearch: React.Dispatch<SearchReducerAction>;
    readonly setModalOpen: (isModalOpen: boolean) => void;
};

const isLocationFilter = (value?: SearchReducerValue): value is LocationFilterType => {
    return (
        typeof value === 'object' &&
        typeof (value as LocationFilterType).lat === 'number' &&
        typeof (value as LocationFilterType).long === 'number' &&
        typeof (value as LocationFilterType).distance === 'number'
    );
};

const MapViewModal = ({
    dispatchSearch,
    geoLocationError,
    indexName,
    initialFilter,
    initialPageFilter,
    initialSearch,
    isMapViewModalOpen,
    lazyLoad,
    pageId = '',
    pageTitle = '',
    pageType,
    searchState,
    setModalOpen,
    showLocationFilter,
}: MapViewModalProps): JSX.Element => {
    const [urlParams, setUrlParams] = useState<UrlParameter | null>(null);
    const { status } = useAlgoliaContext();
    const { results: productResults, scopedResults: algoliaScopedResults } = useInstantSearch();
    const { algoliaConfig, locale, tenant, tenantConfig } = useFragmentContext<FragmentContext>();
    const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);
    const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);
    const [shouldReset, setShouldReset] = useState(false);
    const [filterChanged, setFilterChanged] = useState(false);
    const isDesktop = useBreakpoint(Breakpoint.MD);

    const { filter = {}, pagination, searchTerm, sorting } = searchState;
    const isInitialLocation = hasInitialLocation(filter, initialPageFilter);
    const isInitialPrice = hasInitialPrice(filter, initialPageFilter);
    const activeFiltersCount = useActiveFiltersCount(
        filter,
        isInitialPrice,
        isInitialLocation,
        isMapViewModalOpen,
    );

    const getIndex = (objectType: ObjectType, sortingType?: Sorting): string =>
        getAlgoliaIndex({
            environment: algoliaConfig.environment,
            locale,
            tenant,
            objectType,
            sorting: sortingType,
        });

    const attributeIndex = getIndex(ObjectType.Attribute);
    const results = getAlgoliaScopedResults<Attribute>({
        algoliaScopedResults,
        indexId: AlgoliaIndexId.Attributes,
        indexName: attributeIndex,
        serverState: algoliaConfig.serverState,
    });

    const facets = useMemo(
        () => getFacetFilters(results?.hits, productResults, pagination?.limit),
        [results?.hits, productResults, pagination?.limit],
    );

    const particantFilter = facets?.filter(facet => facet.attribute === AttributeName.Participants);

    const { limit, nbItems, nbPages, products } = useAlgoliaProducts(
        productResults,
        pagination,
        showLocationFilter,
    );

    const { nbBanners, productListItems } = useProductListItems(
        products,
        false,
        pageId,
        tenant,
        true,
    );

    const { size, sizeMobile, useOffset } = pageConfig[pageType];
    const currentPageSize = (!isDesktop ? sizeMobile : size) + nbBanners;

    const offset = useOffset && sizeMobile !== size ? currentPageSize : 0;

    const { hoveredProductIdState, setHoveredProductId } = useContext(HoverContext);

    const activeFiltersCounter = activeFiltersCount > 0 && (
        <Badge className={classNames('ml-1x', styles.filterCounter)}>{activeFiltersCount}</Badge>
    );

    const deviceOsType = detectMobileOS();

    const urlQueryUpdateParams = {
        filter,
        status,
        pagination,
        searchTerm,
        initialPageFilter,
        sorting,
    };

    const { mapRef, markerClusterRef, setMapView } = MapView({
        productListItems,
        setHoveredProductId,
        dispatchSearch,
        modalState: { isMapViewModalOpen, isSidePanelOpen, isDesktop },
        hoveredProductIdState,
        queryId: productResults?.queryID,
        indexName,
    });

    const listType = GA4FilterListType.MapViewMap;

    useTrackProductListItems(products);

    useUrlQueryUpdate(urlQueryUpdateParams);

    useEffect(() => {
        setShouldReset(false);
    }, [filter]);

    useEffect(() => {
        setFilterChanged(!isEqual(filter, initialFilter));
    }, [filter, initialFilter]);

    useEffect(() => {
        if (isMapViewModalOpen) {
            setUrlParams(getUrlParameters());
        }
    }, [isMapViewModalOpen]);

    const closeModal = (clickText?: string): void => {
        if (urlParams) {
            let type = SearchReducerActionType.Location;
            const { distance, lat, locationName, long } = urlParams;

            if (!locationName) {
                type = SearchReducerActionType.LocationFilterReset;
                dispatchSearch({ type });
            } else {
                dispatchSearch({
                    type,
                    value: {
                        lat,
                        long,
                        distance,
                        name: locationName,
                    } as SearchReducerValue,
                });
            }
        }

        cleanupMapRef(mapRef);
        markerClusterRef.current?.remove();
        markerClusterRef.current = null;
        setModalOpen(false);

        if (clickText) trackCloseMapViewClick(clickText);
    };

    const onSidePanelToggle = (): void => {
        trackToggleList(isSidePanelOpen);
        setIsSidePanelOpen(!isSidePanelOpen);
    };

    const onReset = (): void => {
        dispatchSearch({
            type: SearchReducerActionType.SearchReset,
            value: {
                ...initialSearch,
                filter: initialFilter,
            },
        });
    };

    const resetFilter = (): void => {
        onReset();
        setShouldReset(true);
        setIsFilterPanelOpen(false);
        trackFilterInteraction(
            'Reset',
            undefined,
            {
                eventName: GA4EventName.ResetFilter,
                filter_type: 'Reset all',
                list_type: listType,
            },
            '',
        );
    };

    const onFilterChange = (
        type: SearchReducerActionType,
        value?: SearchReducerValue,
        nameParam?: string,
    ): void => {
        if (
            type !== SearchReducerActionType.Location &&
            type !== SearchReducerActionType.LocationFilterReset
        ) {
            dispatchSearch({ type, value, name: nameParam });
        }

        if (type === SearchReducerActionType.Location) {
            setUrlParams(getUrlParameters());
            if (value && isLocationFilter(value)) {
                const { distance, lat, long, name } = value;
                setUrlParams({
                    lat,
                    long,
                    distance,
                    locationName: name,
                });

                setMapView(lat, long, distance, name);
            }
        } else if (type === SearchReducerActionType.LocationFilterReset) {
            setUrlParams({ lat: 0, long: 0, distance: 0, locationName: '' });
            setMapView(0, 0, 0);
        }
    };

    const openSideFilterPanel = (): void => {
        setIsFilterPanelOpen(true);
        setIsSidePanelOpen(true);
        trackFilterInteraction('Open', undefined, {
            eventName: GA4EventName.ClickButton,
            click_element: 'Filterbox open',
            click_text: 'Filter',
            list_type: listType,
        });
    };

    const openFilterPanelMobile = (): void => {
        trackFilterInteraction('Open', undefined, {
            eventName: GA4EventName.ClickButton,
            click_element: 'Filterbox open',
            click_text: 'Filter',
            list_type: listType,
        });
        setIsFilterPanelOpen(true);
    };

    const closeFilterPanel = (): void => {
        trackFilterInteraction('Close', undefined, {
            eventName: GA4EventName.ClickButton,
            click_element: 'Filterbox close',
            click_text: '<',
            list_type: listType,
        });
        setIsFilterPanelOpen(false);
    };

    return (
        <Modal
            isOpen={isMapViewModalOpen}
            onRequestClose={() => closeModal('X')}
            a11yCloseText="Close"
            modalClassName={classNames(styles.modal, 'p-0 p-md-5x position-absolute m-0')}
            modalAddendum={classNames(styles.modalContent, 'position-relative p-0')}
            closeButtonClassName={classNames(
                styles.modalCloseButton,
                'd-none d-md-block position-absolute m-0',
            )}
        >
            <div
                className={classNames(styles.container, 'd-flex overflow-hidden h-100')}
                data-testid="map-view-modal"
            >
                {isDesktop && (
                    <div className="position-relative">
                        <PureButton
                            onClick={onSidePanelToggle}
                            className={classNames(
                                styles.pannelToggleButton,
                                isSidePanelOpen ? styles.open : styles.closed,
                                'position-absolute d-flex justify-content-center align-items-center text-nowrap',
                            )}
                        >
                            <ChevronIcon
                                className={
                                    isSidePanelOpen ? styles.cheveronLeft : styles.cheveronRight
                                }
                            />
                            <span className={styles.pannelToggleText}>Liste einblenden</span>
                        </PureButton>
                        {!isFilterPanelOpen && (
                            <div
                                className={classNames(
                                    styles.filtersContainer,
                                    'd-flex position-absolute',
                                    isSidePanelOpen ? styles.panelOpen : styles.panelClosed,
                                )}
                            >
                                <div className="d-none d-lg-flex">
                                    <PriceFilter
                                        values={[
                                            filter.price?.min || null,
                                            filter.price?.max || null,
                                        ]}
                                        onSubmit={onFilterChange}
                                        shouldReset={shouldReset && !isInitialPrice}
                                        currencyCode={tenantConfig.currency.code}
                                        filter={filter}
                                        listType={listType}
                                    />
                                    <ProductFacets
                                        onFilterChange={onFilterChange}
                                        facets={particantFilter ?? []}
                                        filter={filter}
                                        paginationLimit={limit}
                                        listType={listType}
                                    />
                                </div>

                                <Button
                                    iconLeft={<FilterIcon />}
                                    onClick={openSideFilterPanel}
                                    className="flex-shrink-0"
                                    data-testid="filter-button-desktop"
                                >
                                    <span className="d-none d-lg-block mr-0-5x">
                                        <FormattedMessage defaultMessage="Alle" />
                                    </span>
                                    <FormattedMessage defaultMessage="Filter" />
                                    {activeFiltersCounter}
                                </Button>
                            </div>
                        )}
                        <div
                            className={classNames(
                                styles.productListPanel,
                                'position-absolute start-0 top-0 bottom-0',
                                isSidePanelOpen ? styles.panelOpen : styles.panelClosed,
                            )}
                        >
                            <div className={classNames(styles.listHeader, 'px-3x pt-4x pb-2x')}>
                                <PureButton
                                    onClick={() => closeModal('Zurück zur Listenansicht')}
                                    className={classNames(
                                        styles.backButton,
                                        'd-flex align-items-center mb-3x',
                                    )}
                                >
                                    <ArrowIcon className="mr-1x" />
                                    <FormattedMessage defaultMessage="Zurück zur Listenansicht" />
                                </PureButton>
                                <h1 className={classNames(styles.pageTitle, 'mb-1x')}>
                                    {pageTitle}
                                </h1>

                                <LocationFilter
                                    locationName={filter.location?.name || ''}
                                    onSubmit={(
                                        type: SearchReducerActionType,
                                        value?: SearchReducerValue,
                                    ) => onFilterChange(type, value)}
                                    geoLocationError={geoLocationError}
                                    isMapView
                                    listType={listType}
                                />
                                <p>
                                    <span className="fw-semibold mr-0-5x">{nbItems}</span>
                                    Ergebnisse innerhalb des Kartenbereichs
                                </p>
                            </div>
                            <div
                                className={classNames(
                                    styles.productListContainer,
                                    'overflow-scroll',
                                )}
                            >
                                <MapViewProductListItems
                                    productListItems={productListItems}
                                    currentPageSize={currentPageSize}
                                    lazyLoad={lazyLoad}
                                    searchState={searchState}
                                    initialPageFilter={initialPageFilter}
                                    nbPages={nbPages}
                                    offset={offset}
                                    status={status}
                                    queryId={productResults?.queryID}
                                    indexName={indexName}
                                    dispatchSearch={dispatchSearch}
                                />
                            </div>
                        </div>
                        <FilterSidePanel
                            filter={filter}
                            locale={locale}
                            nbItems={nbItems}
                            hasFilterChanged={filterChanged}
                            sorting={sorting}
                            showLocationFilter={false}
                            shouldReset={shouldReset}
                            isInitialLocation={isInitialLocation}
                            isInitialPrice={isInitialPrice}
                            geoLocationError={geoLocationError}
                            facets={facets}
                            paginationLimit={limit}
                            isSidePanelOpen={isSidePanelOpen}
                            isMapView
                            listType={listType}
                            isFilterPanelOpen={isFilterPanelOpen}
                            onFilterChange={onFilterChange}
                            resetFilter={resetFilter}
                            closeFilterPanel={closeFilterPanel}
                        />
                    </div>
                )}
                {!isDesktop && (
                    <FilterSidePanel
                        filter={filter}
                        locale={locale}
                        nbItems={nbItems}
                        hasFilterChanged={filterChanged}
                        sorting={sorting}
                        showLocationFilter={showLocationFilter}
                        shouldReset={shouldReset}
                        isInitialLocation={isInitialLocation}
                        isInitialPrice={isInitialPrice}
                        geoLocationError={geoLocationError}
                        facets={facets}
                        paginationLimit={limit}
                        isSidePanelOpen={isSidePanelOpen}
                        className={styles.filterPanel}
                        isMapView
                        listType={listType}
                        isFilterPanelOpen={isFilterPanelOpen}
                        resetFilter={resetFilter}
                        onFilterChange={onFilterChange}
                        closeFilterPanel={closeFilterPanel}
                    />
                )}

                <div className="overflow-hidden w-100" id="map" />
            </div>
            {!isDesktop && (
                <>
                    <Button
                        iconLeft={<FilterIcon />}
                        onClick={openFilterPanelMobile}
                        className={classNames(styles.filterPanelButton, 'position-absolute')}
                        data-testid="filter-button-mobile"
                    >
                        <FormattedMessage defaultMessage="Filter" /> {activeFiltersCounter}
                    </Button>
                    <Button
                        color={ButtonColor.Brand}
                        shape={ButtonShape.Pill}
                        iconLeft={<ListViewIcon />}
                        className={classNames(styles.listButton, 'position-absolute', {
                            [styles.iosButton]: deviceOsType === DeviceOs.IOS,
                        })}
                        onClick={() => closeModal('Liste')}
                    >
                        <FormattedMessage defaultMessage="Liste" />
                    </Button>
                </>
            )}
        </Modal>
    );
};

export { MapViewModal };

export default MapViewModal; // eslint-disable-line import/no-default-export
